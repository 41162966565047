/* global saveAs:true */
(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('DocumentStorageDetailController', DocumentStorageDetailController);

  DocumentStorageDetailController.$inject = ['$scope', '$rootScope', '$state', 'DataUtils', 'entity', 'Notification', '$filter'];

  function DocumentStorageDetailController($scope, $rootScope, $state, DataUtils, entity, Notification, $filter) {
    var vm = this;
    vm.documentStorage = entity;
    vm.selectedTab = 'incomingString';
    vm.download = download;
    vm.goBack = goBack;

    activate();

    vm.selected = function (option) {
      vm.selectedTab = option;
    };

    function clear() {
      $state.go('document');
    }

    function activate() {
      if (vm.documentStorage == null) {
        Notification.error({ message: 'Documento não encontrado!' }); // TODO translate
        clear();
      } else {
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.getInputType = DataUtils.getInputType;
        vm.documentStorage.incomingStringType = vm.getInputType(vm.documentStorage.incomingString);
        vm.documentStorage.typedStringType = vm.getInputType(vm.documentStorage.typedString);
        vm.documentStorage.outgoingStringType = vm.getInputType(vm.documentStorage.outgoingString);
        vm.documentStorage.responseStringType = vm.getInputType(vm.documentStorage.responseString);

        if (vm.documentStorage.incomingStringType === DataUtils.JSON_TYPE) {
          vm.documentStorage.incomingString = angular.fromJson(vm.documentStorage.incomingString);
        }
        if (vm.documentStorage.typedStringType === DataUtils.JSON_TYPE) {
          vm.documentStorage.typedString = angular.fromJson(vm.documentStorage.typedString);
        }
        if (vm.documentStorage.outgoingStringType === DataUtils.JSON_TYPE) {
          vm.documentStorage.outgoingString = angular.fromJson(vm.documentStorage.outgoingString);
        }
        if (vm.documentStorage.responseStringType === DataUtils.JSON_TYPE) {
          vm.documentStorage.responseString = angular.fromJson(vm.documentStorage.responseString);
        }
        // eslint-disable-next-line
        var unsubscribe = $rootScope.$on('eDocumentsApp:documentStorageUpdate', function (event, result) {
          vm.documentStorage = result;
        });
        $scope.$on('$destroy', unsubscribe);
      }
    }

    function download() {
      var data = $scope.$eval('vm.documentStorage.' + vm.selectedTab);
      if (angular.isDefined(data)) {
        var blob;
        var type = vm.getInputType(data).toLowerCase();
        switch (type) {
          case 'json':
            blob = new Blob([JSON.stringify(data, null, '\t')], {
              type: 'application/' + type,
            });
            break;
          case 'xml':
            blob = new Blob([data], {
              type: 'application/' + type,
            });
            break;
          default:
            blob = new Blob([data], {
              type: 'application/text',
            });
            type = 'txt';
        }
        var dateTime = $filter('date')(new Date(), 'HHmmddMMyyyy');

        var filename = 'Document_' + vm.documentStorage.documentId + dateTime + '.' + type;
        saveAs(blob, filename, true);
      }
    }

    function goBack() {
      window.history.back();
    }
  }
})();
