(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('CommunicationEntityUserDeleteController', CommunicationEntityUserDeleteController);

  CommunicationEntityUserDeleteController.$inject = ['$uibModalInstance', '$translate', 'communicationEntity', 'user', 'User', 'Notification'];

  function CommunicationEntityUserDeleteController($uibModalInstance, $translate, communicationEntity, user, User, Notification) {
    var vm = this;

    vm.communicationEntity = communicationEntity;
    vm.user = user;
    vm.clear = clear;
    vm.confirmDelete = confirmDelete;

    function clear() {
      $uibModalInstance.dismiss('cancel');
    }

    function confirmDelete() {
      User.removeEntityFromUser(
        {
          userId: user.id,
          entityId: vm.communicationEntity.id,
        },
        function () {
          Notification.success({ message: $translate.instant('userManagement.message.userRemovedFromEntity') });
          $uibModalInstance.close(true);
        },
        function () {
          Notification.error({ message: $translate.instant('userManagement.message.errorRemovingUserFromEntity') });
          $uibModalInstance.close(true);
        }
      );
    }
  }
})();
