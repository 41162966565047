(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('SettingsController', SettingsController);

  SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', 'CommunicationEntity', '$translate', 'DataUtils', '$scope', '$rootScope'];

  function SettingsController(Principal, Auth, JhiLanguageService, CommunicationEntity, $translate, DataUtils, $scope, $rootScope) {
    var vm = this;
    vm.error = null;
    vm.save = save;
    vm.setDocumentFile = setDocumentFile;
    vm.settingsAccount = null;
    vm.userId = null;
    vm.entities = [];
    vm.isManagerForEntity = isManagerForEntity;
    vm.isEmailReceiverForEntity = isEmailReceiverForEntity;
    vm.success = null;
    vm.tmpCropedImgURL = '';
    vm.tmpImageBlob = '';
    vm.tmpImageCropped = undefined;
    vm.clearImage = clearImage;
    /**
     * Store the "settings account" in a separate variable, and not in the shared "account" variable.
     */
    var copyAccount = function (account) {
      return {
        activated: account.activated,
        email: account.email,
        firstName: account.firstName,
        langKey: account.langKey,
        image: account.image,
        imageContentType: account.imageContentType,
        lastName: account.lastName,
        login: account.login,
      };
    };

    Principal.identity(true).then(function (account) {
      vm.settingsAccount = copyAccount(account);
      vm.userId = account.id;
      if (vm.settingsAccount.image && vm.settingsAccount.imageContentType) {
        vm.settingsAccount.imageURL = 'data:' + vm.settingsAccount.imageContentType + ';base64,' + vm.settingsAccount.image;
      }
      if (account && account.id) {
        CommunicationEntity.findAllByUser({ user: account.id }).$promise.then(function (res) {
          vm.entities = res;
        });
      }
    });

    function clearImage() {
      vm.tmpImageURL = undefined;
      vm.tmpImageCropped = null;
      vm.settingsAccount.imageURL = null;
      vm.settingsAccount.image = null;
      vm.settingsAccount.imageContentType = null;
    }

    function save() {
      if (vm.tmpImageCropped && vm.tmpImageCropped !== null) {
        vm.settingsAccount.image = vm.tmpImageCropped.substr(vm.tmpImageCropped.indexOf('base64,') + 'base64,'.length);
        vm.settingsAccount.imageContentType = vm.tmpImageBlob.type;
        vm.tmpImageURL = undefined;
      }

      if (vm.settingsAccount.image && vm.settingsAccount.imageContentType) {
        vm.entidadeImageURL = 'data:' + vm.settingsAccount.imageContentType + ';base64,' + vm.settingsAccount.image;
      }
      vm.tmpImageCropped = undefined;
      Auth.updateAccount(vm.settingsAccount)
        .then(function () {
          vm.error = null;
          vm.success = 'OK';
          Principal.identity(true).then(function (account) {
            vm.settingsAccount = copyAccount(account);
            if (vm.settingsAccount.image && vm.settingsAccount.imageContentType) {
              vm.settingsAccount.imageURL = 'data:' + vm.settingsAccount.imageContentType + ';base64,' + vm.settingsAccount.image;
            }
          });
          JhiLanguageService.getCurrent().then(function (current) {
            if (vm.settingsAccount.langKey !== current) {
              $translate.use(vm.settingsAccount.langKey);
            }
          });
          $rootScope.$broadcast('updateAccountSuccess');
        })
        .catch(function () {
          vm.success = null;
          vm.error = 'ERROR';
        });
    }

    function setDocumentFile($file, invalid) {
      if ($file) {
        DataUtils.toBase64($file, function (base64Data) {
          $scope.$apply(function () {
            vm.tmpImage = base64Data;
            vm.tmpImageContentType = $file.type;
            vm.tmpImageURL = 'data:' + vm.tmpImageContentType + ';base64,' + vm.tmpImage;
          });
        });
      }
      if (invalid.length > 0) {
        Notification.error({
          title: 'Ficheiro Inválido!', // TODO translate
          message: 'Apenas imagens!', // TODO translate
        });
      }
    }

    function isManagerForEntity(item) {
      var itemForUserAndEntity = item.userEntities.filter(function (userEntity) {
        return userEntity.userId === vm.userId && userEntity.entityId === item.id;
      });
      return itemForUserAndEntity[0].manager;
    }

    function isEmailReceiverForEntity(item) {
      var itemForUserAndEntity = item.userEntities.filter(function (userEntity) {
        return userEntity.userId === vm.userId && userEntity.entityId === item.id;
      });
      return itemForUserAndEntity[0].emailReceiver;
    }
  }
})();
