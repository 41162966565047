(function () {
  'use strict';

  angular.module('eDocumentsApp').controller('UserManagementDialogController', UserManagementDialogController);

  UserManagementDialogController.$inject = [
    '$state',
    'entity',
    'User',
    'JhiLanguageService',
    'DataUtils',
    '$scope',
    'CommunicationEntity',
    'Notification',
    'Principal',
  ];

  function UserManagementDialogController($state, entity, User, JhiLanguageService, DataUtils, $scope, CommunicationEntity, Notification, Principal) {
    var vm = this;
    vm.clear = clear;
    vm.setDocumentFile = setDocumentFile;
    vm.removeUserFromEntity = removeUserFromEntity;
    vm.addUserToEntity = addUserToEntity;
    vm.toggleManager = toggleManager;
      vm.toggleEmailReceiver = toggleEmailReceiver;
    vm.isManagerForEntity = isManagerForEntity;
      vm.isEmailReceiverForEntity = isEmailReceiverForEntity;
    vm.isUserAllowedToChangeAuthorities = isUserAllowedToChangeAuthorities;
    vm.languages = null;
    vm.save = save;
    vm.clearImage = clearImage;
    vm.user = entity;
    vm.tmpCropedImgURL = '';
    vm.tmpImageBlob = '';
    vm.tmpImageCropped = undefined;

    vm.clearFilter = clearFilter;
    vm.search = search;

    activate();
    Principal.identity().then(function (account) {
      vm.currentAccount = account;
    });

    function activate() {
      var ids = [];
      if (vm.user.authorities && vm.user.authorities.length > 0) {
        for (var j = 0; j < vm.user.authorities.length; j++) {
          ids.push(vm.user.authorities[j]);
        }
      } else {
        vm.user.authorities = [];
      }
      var authoritiesTmp = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_PARTNER', 'ROLE_SUPERADMIN', 'ROLE_BETATESTER', 'ROLE_INTEGRATION', 'ROLE_DIGITAL_ARCHIVE'];
      vm.authorities = [];
      for (var i = 0; i < authoritiesTmp.length; i++) {
        if (!ids.includes(authoritiesTmp[i])) {
          vm.authorities.push(authoritiesTmp[i]);
        }
      }

      if (vm.user && vm.user.id) {
        CommunicationEntity.findAllByUser({ user: vm.user.id }).$promise.then(function (res) {
          vm.user.entities = res;
        });
      }

      if (vm.user.image && vm.user.imageContentType) {
        vm.user.imageURL = 'data:' + vm.user.imageContentType + ';base64,' + vm.user.image;
      }
    }

    function clearFilter() {
      vm.searchQuery = undefined;
    }

    function search() {
      var idsEntities = [];
      if (vm.user.entities && vm.user.entities.length > 0) {
        for (var j = 0; j < vm.user.entities.length; j++) {
          idsEntities.push(vm.user.entities[j].id);
          idsEntities[vm.user.entities[j].id] = vm.user.entities[j].manager == null ? false : vm.user.entities[j].manager;
        }
      }
      CommunicationEntity.searchByName({ search: vm.searchQuery }).$promise.then(
        function (res) {
          vm.entities = [];
          for (var i = 0; i < res.length; i++) {
            if (!idsEntities.includes(res[i].id)) {
              vm.entities.push(res[i]);
            }
          }
        },
        function () {
          Notification.error({ message: 'Pesquisa inválida!' }); // TODO translate
        }
      );
    }

    JhiLanguageService.getAll().then(function (languages) {
      vm.languages = languages;
    });

    function clear() {
      $state.go('user-management');
    }

    function onSaveSuccess() {
      vm.isSaving = false;
      $state.go('user-management');
    }

    function onSaveError() {
      vm.isSaving = false;
    }

    function toggleManager(item) {
      var itemForUserAndEntity = item.userEntities.filter(function (userEntity) {
        return userEntity.userId === vm.user.id && userEntity.entityId === item.id;
      });
      User.updateUserEntity(
        {
          userId: vm.user.id,
          entityId: item.id,
          manager: !vm.isManagerForEntity(item),
          emitter: itemForUserAndEntity[0].emitter,
          receptor: itemForUserAndEntity[0].receptor,
          emailReceiver: itemForUserAndEntity[0].emailReceiver,
        },
        function () {
          Notification.success({ message: 'Utilizador atualizado!' }); // TODO translate
        },
        function () {
          Notification.error({ message: 'Ocorreu um erro ao atualizar o utilizador!' }); // TODO translate
        }
      );
      itemForUserAndEntity[0].manager = !itemForUserAndEntity[0].manager;
    }

      function toggleEmailReceiver(item) {
          var itemForUserAndEntity = item.userEntities.filter(function (userEntity) {
              return userEntity.userId === vm.user.id && userEntity.entityId === item.id;
          });
          User.updateUserEntity(
              {
                  userId: vm.user.id,
                  entityId: item.id,
                  manager: itemForUserAndEntity[0].manager,
                  emitter: itemForUserAndEntity[0].emitter,
                  receptor: itemForUserAndEntity[0].receptor,
                  emailReceiver: !vm.isEmailReceiverForEntity(item),
              },
              function () {
                  Notification.success({ message: 'Utilizador atualizado!' }); // TODO translate
              },
              function () {
                  Notification.error({ message: 'Ocorreu um erro ao atualizar o utilizador!' }); // TODO translate
              }
          );
          itemForUserAndEntity[0].emailReceiver = !itemForUserAndEntity[0].emailReceiver;
      }

    function isManagerForEntity(item) {
      var itemForUserAndEntity = item.userEntities.filter(function (userEntity) {
        return userEntity.userId === vm.user.id && userEntity.entityId === item.id;
      });
      return itemForUserAndEntity[0].manager;
    }

      function isEmailReceiverForEntity(item) {
          var itemForUserAndEntity = item.userEntities.filter(function (userEntity) {
              return userEntity.userId === vm.user.id && userEntity.entityId === item.id;
          });
          return itemForUserAndEntity[0].emailReceiver;
      }

      function removeUserFromEntity(item) {
          User.removeEntityFromUser(
              {
                  userId: vm.user.id,
                  entityId: item.id,
              },
              function () {
                  Notification.success({ message: 'O utilizador removido da entidade!' }); // TODO translate
              },
              function () {
                  Notification.error({ message: 'Ocorreu um erro ao remover o utilizador da entidade!' }); // TODO translate
              }
          );
          item.manager = false;
          return item;
      }

    function addUserToEntity(item) {
      var userEntityObject = {
        userId: vm.user.id,
        entityId: item.id,
        manager: false,
        emitter: true,
        receptor: true,
          emailReceiver: false,
      };

      User.addEntityToUser(
        userEntityObject,
        function () {
          Notification.success({ message: 'Utilizador associado à entidade!' });
        },
        function () {
          Notification.error({ message: 'Ocorreu um erro ao associar o utilizador à entidade!' });
        }
      );
      item.userEntities.push(userEntityObject);
      return item;
    }

    function clearImage() {
      vm.tmpImageURL = undefined;
      vm.tmpImageCropped = null;
      vm.user.imageURL = null;
      vm.user.image = null;
      vm.user.imageContentType = null;
    }

    function save() {
      vm.isSaving = true;
      if (vm.tmpImageCropped && vm.tmpImageCropped !== null) {
        vm.user.image = vm.tmpImageCropped.substr(vm.tmpImageCropped.indexOf('base64,') + 'base64,'.length);
        vm.user.imageContentType = vm.tmpImageBlob.type;
        vm.tmpImageURL = undefined;
      }

      if (vm.user.image && vm.user.imageContentType) {
        vm.entidadeImageURL = 'data:' + vm.user.imageContentType + ';base64,' + vm.user.image;
      }
      if (angular.isDefined(vm.user.id) && vm.user.id !== null) {
        User.update(vm.user, onSaveSuccess, onSaveError);
      } else {
        User.save(vm.user, onSaveSuccess, onSaveError);
      }
    }

    function setDocumentFile($file, invalid) {
      if ($file) {
        DataUtils.toBase64($file, function (base64Data) {
          $scope.$apply(function () {
            vm.tmpImage = base64Data;
            vm.tmpImageContentType = $file.type;
            vm.tmpImageURL = 'data:' + vm.tmpImageContentType + ';base64,' + vm.tmpImage;
          });
        });
      }
      if (invalid.length > 0) {
        Notification.error({
            title: 'Ficheiro Inválido!', // TODO translate
            message: 'Apenas imagens!', // TODO translate
        });
      }
    }

    function isUserAllowedToChangeAuthorities() {
      if (vm.currentAccount) {
        return vm.currentAccount.authorities.includes('ROLE_SUPERADMIN');
      }
      return false;
    }
  }
})();
